<template>
  <div :class="containerClasses">
    <label class="col-form-label col-form-label--accent">{{noteHeading}}</label>
    <div>
      <b-row>
        <b-col md="6" lg="4">
          <p>
            <strong>Stato originale</strong>
            <br />
            <badge
              :status="note.fromStatus"
              :statuses="statuses"
            />
          </p>
        </b-col>
        <b-col md="6" lg="4">
          <p>
            <strong>Modificato in</strong>
            <br />
            <badge
              :status="note.toStatus"
              :statuses="statuses"
            />
          </p>
        </b-col>
        <b-col md="6" lg="4">
          <p>
            <strong>In data</strong>
            <br />
            {{statusChangeDate}}
          </p>
        </b-col>
      </b-row>
      <div class="border-top mx-n2 p-2" v-if="note.body">
        <p>
          <strong>Nota</strong>
          <br />
          {{note.body}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '../../utils/formatterHelper';

const Badge = () => import('@/components/helpers/Badge.vue');

export default {
  name: 'UserInvoiceNote',
  components: { Badge },
  props: {
    index: Number,
    note: Object,
    statuses: String,
  },
  computed: {
    containerClasses() {
      if (this.index > 0) return 'mt-3';
      return '';
    },
    noteHeading() {
      return `Nota #${this.index}`;
    },
    statusChangeDate() {
      return formatDateTime(this.note.creationDate);
    },
  },
};
</script>
